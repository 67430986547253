import { useState } from "react";
import styles from "./loginModal.module.css";
import Modal from "react-modal";
import close from "../../images/close.png";
import loginCall from "../../images/loginCall.png";
import loginEmail from "../../images/mail.png";
import Lottie from "lottie-react";
import loginAnimation from "../../animations/loginAnimation.json";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { enqueueSnackbar } from "notistack";

interface IProps {
  modalIsOpen: boolean;
  setModalIsOpen: (value: boolean) => void;
}
export default function LoginModal({ modalIsOpen, setModalIsOpen }: IProps) {
  const [otpModal, setOtpModal] = useState(true);
  const [email, setEmail] = useState("");
  const [otp, setOtp] = useState("");
  const navigate = useNavigate();
  const [loginBtn, setLoginBtn] = useState(false);
  const [verifyBtn, setVerifyBtn] = useState(false);
  const handleLogin = (e: any) => {
    e.preventDefault();
    setLoginBtn(true);
    axios
      .post("/api/user/login", {
        email: email.toLowerCase(),
      })
      .then((res) => {
        enqueueSnackbar("OTP Sent!", { variant: "success" });
        openOTP();
        setLoginBtn(false);
      })
      .catch((err) => {
        enqueueSnackbar(err.response.data.message, { variant: "error" });
        setLoginBtn(false);
      });
  };
  const handleOtp = (e: any) => {
    e.preventDefault();
    setVerifyBtn(true);
    axios
      .post("/api/user/login/otp", {
        email: email.toLowerCase(),
        otp: otp,
      })
      .then((res) => {
        closeOTP();
        closeModal();
        enqueueSnackbar("successful", { variant: "success" });
        setTimeout(() => {
          navigate(0);
        }, 1500);
      })
      .catch((err) => {
        setVerifyBtn(false);
        enqueueSnackbar(err.response.data.message, { variant: "error" });
      });
  };

  function closeModal() {
    setModalIsOpen(false);
  }
  function openOTP() {
    setOtpModal(false);
  }
  function closeOTP() {
    setOtpModal(true);
  }
  return (
    <>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        className={styles.content}
        contentLabel="Example Modal"
        style={{ overlay: { zIndex: 100 } }}
      >
        <div className={styles.container}>
          <div className={styles.top}>
            <img
              src={close}
              alt="close"
              className={styles.close}
              onClick={closeModal}
            />
            <div className={styles.loginTextDiv}>
              <p className={styles.loginText}>Universe Transport</p>
            </div>
          </div>
          <div className={styles.bottom}>
            <Lottie
              className={styles.loginAniamtion}
              animationData={loginAnimation}
            />
            {otpModal ? (
              <>
                <p className={styles.fromText}>
                  Please enter your Email to login
                </p>
                <form>
                  <div className={styles.inputComponent}>
                    <img
                      className={styles.icons}
                      src={loginEmail}
                      alt="profile"
                    ></img>
                    <input
                      id="name-input"
                      type="email"
                      placeholder="Email"
                      className={styles.input}
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                  <button
                    disabled={loginBtn}
                    className={styles.btn}
                    id="login-button"
                    type="submit"
                    onClick={handleLogin}
                  >
                    {loginBtn ? "Please Wait..." : "Login"}
                  </button>
                </form>
              </>
            ) : (
              <div className={styles.bottom}>
                <p className={styles.fromText}>Please enter 6-digit code</p>
                <form>
                  <div className={styles.inputComponent}>
                    <input
                      id="name-input"
                      type="text"
                      placeholder="OTP code"
                      className={styles.input}
                      style={{ marginLeft: "10px" }}
                      value={otp}
                      onChange={(e) => setOtp(e.target.value)}
                    />
                  </div>
                  <button
                    disabled={verifyBtn}
                    className={styles.btn}
                    id="login-button"
                    type="submit"
                    onClick={handleOtp}
                  >
                    {verifyBtn ? "Please Wait..." : "Confirm"}
                  </button>
                </form>
                <p style={{ cursor: "pointer" }} onClick={closeOTP}>
                  Wrong Email?
                </p>
              </div>
            )}
          </div>
        </div>
      </Modal>
    </>
  );
}
