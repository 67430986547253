import { useEffect, useState } from "react";
import styles from "./signup-modal.module.css";
import Modal from "react-modal";
import close from "../../images/close.png";
import profile from "../../images/profile.png";
import mail from "../../images/mail.png";
import Lottie from "lottie-react";
import loginAnimation from "../../animations/loginAnimation.json";
import loginCall from "../../images/loginCall.png";
import LoginModal from "../login-modal/loginModal";
import axios from "axios";
import { enqueueSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";

interface IProps {
  modalIsOpen: boolean;
  setModalIsOpen: (value: boolean) => void;
}

export default function SignUpModal({ modalIsOpen, setModalIsOpen }: IProps) {
  const [otpModal, setOtpModal] = useState(true);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [otp, setOpt] = useState("");
  const [activateLogin, setActivateLogin] = useState(false);
  const [signUpBtn, setSignUpBtn] = useState(false);
  const [verifyBtn, setVerifyBtn] = useState(false);
  const navigate = useNavigate();

  const handleOtp = (e: any) => {
    e.preventDefault();
    setVerifyBtn(true);
    axios
      .post("/api/user/verify", {
        email: email.toLowerCase(),
        otp: otp,
      })
      .then((res) => {
        enqueueSnackbar("Verfied Successfully", { variant: "success" });

        closeOTP();
        closeModal();
        setVerifyBtn(false);
        setTimeout(() => {
          navigate(0);
        }, 1500);
      })
      .catch((err) => {
        setVerifyBtn(false);
        enqueueSnackbar(err.response.data.message, { variant: "error" });
      });
  };
  const handleSignUp = (e: any) => {
    e.preventDefault();
    setSignUpBtn(true);
    axios
      .post("/api/user", {
        email: email.toLowerCase(),
        phone: phoneNumber,
        firstName: firstName,
        lastName: lastName,
      })
      .then((res) => {
        setSignUpBtn(false);
        enqueueSnackbar("OTP Sent!", { variant: "success" });
        openOTP();
      })
      .catch((err) => {
        setSignUpBtn(false);
        enqueueSnackbar(err.response.data.message, { variant: "error" });
      });
  };

  function closeModal() {
    setModalIsOpen(false);
  }
  function openOTP() {
    setOtpModal(false);
  }
  function closeOTP() {
    setOtpModal(true);
  }
  const activeloginModal = () => {
    setActivateLogin(true);
    closeModal();
  };
  return (
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      className={styles.content}
      contentLabel="Example Modal"
      style={{ overlay: { zIndex: 100 } }}
    >
      <div className={styles.container}>
        <div className={styles.top}>
          <img
            src={close}
            alt="close"
            className={styles.close}
            onClick={closeModal}
          />
          <div className={styles.loginTextDiv}>
            <p className={styles.loginText}>Universe Transport</p>
          </div>
        </div>

        {otpModal ? (
          <>
            <div className={styles.bottom}>
              <Lottie
                className={styles.loginAniamtion}
                animationData={loginAnimation}
              />
              <p className={styles.fromText}>Enter your Info to sign up</p>
              <form>
                <div className={styles.inputComponent}>
                  <img
                    className={styles.icons}
                    src={profile}
                    alt="profile"
                  ></img>
                  <input
                    id="name-input"
                    type="text"
                    placeholder="First Name"
                    className={styles.input}
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                  />
                </div>
                <div className={styles.inputComponent}>
                  <img
                    className={styles.icons}
                    src={profile}
                    alt="profile"
                  ></img>
                  <input
                    id="name-input"
                    type="text"
                    placeholder="Last Name"
                    className={styles.input}
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                  />
                </div>
                <div className={styles.inputComponent}>
                  <img
                    className={styles.icons}
                    src={loginCall}
                    alt="profile"
                  ></img>
                  <input
                    id="name-input"
                    type="text"
                    placeholder="Phone Number"
                    className={styles.input}
                    value={phoneNumber}
                    onChange={(e) => setPhoneNumber(e.target.value)}
                  />
                </div>
                <div className={styles.inputComponent}>
                  <img className={styles.icons} src={mail} alt="profile"></img>
                  <input
                    id="name-input"
                    type="mail"
                    placeholder="Email"
                    className={styles.input}
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
                <button
                  disabled={signUpBtn}
                  className={styles.btn}
                  id="login-button"
                  type="submit"
                  onClick={handleSignUp}
                >
                  {signUpBtn ? "Please Wait..." : "Sign Up"}
                </button>
              </form>
            </div>
          </>
        ) : (
          <div className={styles.bottom2}>
            <Lottie
              className={styles.loginAniamtion}
              animationData={loginAnimation}
            />
            <div className={styles.bottom}>
              <p className={styles.fromText}>Please enter 6-digit code</p>
              <form>
                <div className={styles.inputComponent}>
                  <input
                    id="name-input"
                    type="text"
                    placeholder="OTP code"
                    className={styles.input}
                    style={{ marginLeft: "10px" }}
                    value={otp}
                    onChange={(e) => setOpt(e.target.value)}
                  />
                </div>
                <button
                  disabled={verifyBtn}
                  className={styles.btn}
                  id="login-button"
                  type="submit"
                  onClick={handleOtp}
                >
                  {verifyBtn ? "Please Wait..." : "Verify"}
                </button>
              </form>
            </div>
          </div>
        )}
      </div>
      {/* <LoginModal modalIsOpen={activateLogin} setModalIsOpen={setActivateLogin}/> */}
    </Modal>
  );
}
